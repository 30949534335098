import { useContext, useEffect, useState, useCallback } from 'react'
import { QualifiedVisitorFields } from '../utils/qualified/qualifiedTypes'
import { useScript } from './useScript'
import { parse } from 'query-string'
import { useLocation } from 'react-router-dom'
import { LeadContext, LeadExperiment, useUpdateLeadModel } from '../models/leads'
import { trackEvent } from '../utils/analytics'

const API_NAME = 'experience-1724169767606'

const IFRAME_ID = 'q-messenger-frame'

const HIDE_QUALIFIED_CSS =
  'display: none !important; visibility: hidden !important; opacity: 0 !important; pointer-events: none !important;'

/**
 * In order to hide the default launching button, the Ramp logo
 *
 * We need to keep the Qualified chat always opened
 *
 * When we close the Qualified chat natively, we force it to stay open to hide the button
 *
 * Instead we hide and show in inline CSS
 */
export const useQualified = () => {
  const isQualifiedInDOM = typeof window.qualified !== 'undefined'

  const [isIframeOpen, setIsIframeOpen] = useState(false)

  const [isScriptFirstLoaded, setIsScriptFirstLoaded] = useState(isQualifiedInDOM)

  const { lead, setLead } = useContext(LeadContext)

  const location = useLocation()

  const { search } = location

  const { email: queryStringEmail } = parse(search)

  // If a user visits prequal directly with a + sign in email, decoder converts it to a space, so we need to convert it back here
  const email = (queryStringEmail as string)?.replace(' ', '+') ?? undefined

  const { status: isQualifiedLoaded, loadScript } = useScript({
    src: 'https://js.qualified.com/qualified.js?token=iQh2Juj87NdoEXjR',
    params: { attributes: [] },
    loadOnMount: false,
  })

  const hideIframe = () => {
    const qualifiedIframe = document.getElementById(IFRAME_ID)
    if (qualifiedIframe instanceof HTMLElement) {
      qualifiedIframe.style.cssText = HIDE_QUALIFIED_CSS
    }

    setIsIframeOpen(false)
  }

  const showIframe = () => {
    const qualifiedIframe = document.getElementById(IFRAME_ID)
    if (qualifiedIframe instanceof HTMLElement) {
      qualifiedIframe.style.cssText = ''
    }

    setIsIframeOpen(true)
  }

  const updateLead = useUpdateLeadModel()

  const initExperimentGroup = useCallback(async () => {
    if (!lead) {
      throw new Error('Expected valid experiment group')
    }

    const experimentGroup =
      Math.random() < 0.5
        ? LeadExperiment.QualifiedChatBotControl
        : LeadExperiment.QualifiedChatBotTreatment

    const updatedLead = await updateLead(lead.id, { experiment: experimentGroup })
    setLead((prevLeadModel) => {
      if (prevLeadModel) {
        return { ...prevLeadModel, ...updatedLead }
      }

      return prevLeadModel
    })
  }, [lead, updateLead, setLead])

  useEffect(() => {
    const initQualified = () => {
      if (typeof email === 'string') {
        const identity: QualifiedVisitorFields = {
          email,
        }
        window.qualified('identify', identity)
      }

      window.qualified('open')
      window.qualified('showExperience', API_NAME)
      window.qualified('handleMessengerEvents', (event: string) => {
        if (event === 'messengerClosed') {
          hideIframe()
        }
      })
      showIframe()
      setIsScriptFirstLoaded(true)
    }

    if (isQualifiedLoaded === 'ready' && !isScriptFirstLoaded && isQualifiedInDOM) {
      initQualified()
    }
  }, [isScriptFirstLoaded, isQualifiedLoaded, email, isQualifiedInDOM])

  const toggleIframe = () => {
    if (isIframeOpen) {
      hideIframe()
    } else {
      window.qualified('open')
      showIframe()
    }
  }

  const startQualified = () => {
    trackEvent('Qualified Chat Bot Experiment Clicked')

    if (!isScriptFirstLoaded && !isQualifiedInDOM) {
      loadScript()
    } else {
      toggleIframe()
    }
  }

  return {
    startQualified,
    initExperimentGroup,
    // TODO: Temporary disable Qualified Chat Bot Experiment
    isInQualifiedExperiment: false,
  }
}
