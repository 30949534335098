import { RyuButton, RyuFlex, RyuIcon, RyuButtonProps } from '@ramp/ryu'
import { styled } from 'styled-components'
import { useQualified } from '../hooks/useQualified'
import { isWithinESTBusinessHours } from '../utils/isWithinESTBusinessHours'

export const TalkToSalesButton = ({ ...rest }: RyuButtonProps = {}) => {
  const { isInQualifiedExperiment, startQualified } = useQualified()

  if (!isInQualifiedExperiment || !isWithinESTBusinessHours()) {
    return null
  }

  return (
    <RyuButton variant='prime' onClick={startQualified} {...rest}>
      <TextContainer alignItems='center' gapSize={2}>
        Start live chat
        <ChatIconContainer>
          <GreenDot />
          <RyuIcon type='message-square' />
        </ChatIconContainer>
      </TextContainer>
    </RyuButton>
  )
}

const GreenDot = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.ryuSpacing[4]}rem;
  left: ${({ theme }) => theme.ryuSpacing[3]}rem;
  border-radius: ${({ theme }) => theme.ryuCorners.round};

  width: ${({ theme }) => theme.ryuSpacing[2]}rem;
  height: ${({ theme }) => theme.ryuSpacing[2]}rem;
  background-color: ${({ theme }) => theme.colors.darkGreen};
`

const TextContainer = styled(RyuFlex)`
  padding-right: ${({ theme }) => theme.ryuSpacing[3]}rem;
  padding-left: ${({ theme }) => theme.ryuSpacing[3]}rem;
`

const ChatIconContainer = styled.div`
  position: relative;
  overflow: visible;
`
