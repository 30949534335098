import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import CookiesUtil from '~/src/utils/cookies'
import { RoutingDirection, useFetchLeadModel, useUpdateLeadModel } from '~/src/models/leads'
import { trackEvent } from '~/src/utils/analytics'
import { logError } from '~/src/utils/errors'
import { ContactUsSurvey } from './ContactUsSurvey'
import { RyuGrid, RyuLoadingOverlay } from '@ramp/ryu'
import { BankBalance, useCreateLead } from '~/src/hooks/leads'
import { ContactUsFormValues } from './types'
import ContactUsLeftSide from './ContactUsLeftSide'
import { styled } from 'styled-components'
import { showChilipiper } from '~/src/utils/chilipiper'
import pollPromise from '~/src/utils/poll'
import ContactUsThankYouPage from './ContactUsThankYouPage'

const FullHeightGrid = styled(RyuGrid)`
  height: 100%;
  background-color: #fff;
`

export enum ContactUsStatus {
  BOOKED_MEETING,
  SUBMITTED_WITHOUT_MEETING,
  INELIGIBLE,
  NOT_SUBMITTED,
}

interface ContactSalesWrapperProps {
  onSubmit?: (data: ContactUsFormValues) => void
}

const ContactUsSurveyWrapper: FunctionComponent<ContactSalesWrapperProps> = (props) => {
  const { onSubmit } = props

  const [isUpdating, setIsUpdating] = useState(false)
  const [bookingStatus, setBookingStatus] = useState<ContactUsStatus>(ContactUsStatus.NOT_SUBMITTED)
  const [has25k, setHas25k] = useState<boolean | null>(null)
  const createLead = useCreateLead()
  const fetchLeadModel = useFetchLeadModel()
  const updateLead = useUpdateLeadModel()

  const generateLeadAndPollForCreation = useCallback(
    async (data: ContactUsFormValues) => {
      // make a partial lead
      const partialLead = await createLead(data.email, {
        discovery_method: 'contact_sales_website',
        first_name: data.first_name,
        last_name: data.last_name,
        company_name: data.company_name,
        employee_count: data.company_size?.value,
        estimated_monthly_spend: data.monthly_spend?.value,
        bank_balance: data.has_over_25k ? BankBalance.OVER_25K : BankBalance.UNDER_25K,
        job_title: data.job_title,
        additional_notes: data.additional_notes,
      })
      if (!partialLead) {
        throw new Error('Lead creation failed')
      }

      const leadId = partialLead.id
      // Poll for lead creation
      // This will error if we try more than 5 times
      // This error will bubble up and be caught in the catch block
      const lead = await pollPromise(() => fetchLeadModel(leadId), {
        interval: 1000,
        maxAttempts: 5,
        condition: (lead) => !!lead?.routing_direction,
      })

      return lead
    },
    [createLead, fetchLeadModel]
  )

  const onFormSubmit = useCallback(
    async (data: ContactUsFormValues) => {
      try {
        setIsUpdating(true)

        await window.analytics?.identify({ email: data.email })

        trackEvent('Email Form Submitted', {
          email: data.email,
          discovery_method: 'contact_sales_website',
          pathname: window.location.pathname,
          cvr_component: 'hero_cta',
        })

        const { id: leadId, routing_direction } = await generateLeadAndPollForCreation(data)
        if (data.has_over_25k === false) {
          setHas25k(false)
          setBookingStatus(ContactUsStatus.INELIGIBLE)
          return
        }

        setHas25k(true)

        if (!routing_direction) {
          throw new Error('Lead creation failed')
        }

        if (routing_direction === RoutingDirection.SALES_QUALIFIED) {
          const cookies = CookiesUtil.getAll()
          const eventPayload = {
            fbp: cookies._fbp,
            fbc: cookies._fbc,
            first_name: data.first_name,
            last_name: data.last_name,
            company_name: data.company_name,
            company_email: data.email,
            employee_count: data.company_size?.value,
            monthly_spend: data.monthly_spend?.value ?? null,
            bank_balance: data.has_over_25k ? BankBalance.OVER_25K : BankBalance.OVER_25K,
            discovery_method: 'contact_sales_website',
          }

          showChilipiper({
            beforeSubmit: () => {
              trackEvent('Chilipiper Widget - Calendar Requested', eventPayload)
            },
            onRouted: () => {
              trackEvent('Chilipiper Widget - Calendar Shown', eventPayload)
            },
            onSuccess: async () => {
              await updateLead(leadId, {
                requested_demo: true,
              })
              trackEvent('Chilipiper Widget - Meeting Successfully Booked', eventPayload)
              setBookingStatus(ContactUsStatus.BOOKED_MEETING)
              if (onSubmit) {
                onSubmit(data)
              }
            },
            onClose: () => {
              trackEvent('Chilipiper Widget - User Exited Without Booking Meeting', eventPayload)
              setBookingStatus(ContactUsStatus.SUBMITTED_WITHOUT_MEETING)
            },
            lead: {
              FirstName: data.first_name,
              LastName: data.last_name,
              Email: data.email,
            },
          })
        } else {
          setBookingStatus(ContactUsStatus.SUBMITTED_WITHOUT_MEETING)
        }
      } catch (e: any) {
        logError.high(e, {
          tags: { owner: 'akash-ramp' },
        })
        setBookingStatus(ContactUsStatus.INELIGIBLE)
      } finally {
        setIsUpdating(false)
      }
    },
    [generateLeadAndPollForCreation, updateLead, onSubmit]
  )

  if (bookingStatus !== ContactUsStatus.NOT_SUBMITTED) {
    return <ContactUsThankYouPage status={bookingStatus} />
  }

  return (
    <Fragment>
      {isUpdating && <RyuLoadingOverlay />}
      <FullHeightGrid columns={2} responsiveColumns={1}>
        <ContactUsLeftSide />
        <ContactUsSurvey has25k={has25k} onSubmit={onFormSubmit} />
      </FullHeightGrid>
    </Fragment>
  )
}

export default ContactUsSurveyWrapper
